@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css);
.markdown {
  width: 100%;
  height: 100%; }
  .markdown > div {
    display: flex; }
    .markdown > div .content {
      flex: 1 1;
      position: relative;
      flex-basis: 82%;
      float: right;
      padding: 5rem 4rem; }
      .markdown > div .content:after {
        clear: both;
        display: block;
        position: relative;
        content: "	"; }
      .markdown > div .content h1, .markdown > div .content h2, .markdown > div .content h3, .markdown > div .content h4, .markdown > div .content h5, .markdown > div .content h6 {
        color: #232128;
        display: block;
        padding: 1.5rem 0;
        font-weight: 900; }
      .markdown > div .content h1 {
        font-size: 3rem; }
      .markdown > div .content h2 {
        font-size: 2.5rem; }
      .markdown > div .content h3 {
        font-size: 2.2rem; }
      .markdown > div .content h4 {
        font-size: 1.9rem; }
      .markdown > div .content h5 {
        font-size: 1.7rem; }
      .markdown > div .content h6 {
        font-size: 1.5rem; }
      .markdown > div .content p {
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.5;
        color: #232128; }
      .markdown > div .content pre {
        margin: 1rem auto;
        padding: 2rem;
        font-size: 1.2rem;
        font-family: monospace;
        background-color: #eeeeee; }
      .markdown > div .content code {
        font-family: monospace;
        padding: 0.2rem;
        background-color: #eeeeee; }
      .markdown > div .content ul {
        padding-left: 2rem;
        margin: 1rem auto; }
        .markdown > div .content ul li {
          font-size: 1.5rem;
          font-weight: 300;
          line-height: 1.5;
          color: #232128; }
      .markdown > div .content a {
        color: #232128;
        font-weight: 300;
        text-decoration: underline; }
      .markdown > div .content table {
        width: 100%;
        margin: 1.5rem auto; }
        .markdown > div .content table thead {
          background-color: #eeeeee; }
          .markdown > div .content table thead th {
            text-align: left;
            padding: 1rem;
            font-size: 1.6rem; }
        .markdown > div .content table tbody {
          background-color: #ffffff; }
          .markdown > div .content table tbody td {
            border: thin solid #eeeeee;
            font-size: 1.4rem;
            padding: 0.6rem 1rem; }
.spell-history-rows {
  max-height: 60vh;
  overflow-y: auto;
}
